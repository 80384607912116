<template>
    <div>
        <v-title title="Underpaid Orders"></v-title>

        <title-box>
            <div class="row">
                <div class="col-12">
                    <h1>Underpaid orders | Order slips</h1>
                </div>
            </div>
        </title-box>

        <div class="container-fluid mt-3">
            <template v-if="hasPermission('listOrderSlipsByApplication')">
                <mercur-card class="mb-4 mt-n4">
                    <table-search-filter @change="applyFilter" :filterFields="filterFields" :dropdownFilters="dropdownFilters" :has-date-range="true"></table-search-filter>
                </mercur-card>
                <pagination :pagination="pagination" @change="changeAmountOfItems"></pagination>
                <merchant-data-table class="shadow" :options="options" :url="url" ref="table" @paginationSet="updatePagination"></merchant-data-table>
            </template>
            <span v-else>
                Not allowed to see this view
            </span>
        </div>
    </div>
</template>

<script>
import CONFIG from '@root/config'

import MerchantDataTable from '@/components/elements/MerchantDataTable'
import Pagination from '@/components/elements/table/Pagination'
import Currency from '@/components/elements/Currency'
import TableSearchFilter from '@/components/elements/table/SearchFilter'
import { mapState, mapActions } from 'vuex'
export default {
    name: 'UnderpaidOrdersView',
    components: {
        MerchantDataTable,
        Pagination,
        TableSearchFilter,
    },
    data () {
        return {
            url: CONFIG.API.ROUTES.ORDERS.SLIPS.OVERVIEW.replace('{type}', 'DEBIT').replace('{agentId}', this.$store.state.auth.user.accountId),
            pagination: {},
            amountOfDisplayedItems: 50,
            isLoading: false,
            isDownloading: false,
            filterFields: {
                'accountNumber': { 'label': 'Account Number' },
                'slipNumber': { 'label': 'Slip Number' },
                'orderNumber': { 'label': 'OrderId' },
            },
            dropdownFilters: [
                {
                    column: 'status',
                    label: 'Slip Status',
                    operator: 'equals',
                    values: {
                        'New': 'NEW',
                        'Sent': 'SENT',
                        'Paid': 'PAID',
                    },
                },
            ],
            options: {
                columns: {
                    'Slip Number': {
                        field: 'slipNumber',
                        filterParams: {
                            filterOptions: ['contains'],
                            newRowsAction: 'keep',
                            clearButton: true,
                            suppressAndOrCondition: true,
                            textFormatter: (value) => {
                                return value.trim()
                            },
                        },
                    },
                    'Account Number': {
                        field: 'accountNumber',
                        valueFormatter: params => params.data.accountNumber || '-',
                    },
                    'Order Id': {
                        field: 'orderNumber',
                        link: (value, data) => {
                            return {
                                name: 'OrderWrapper',
                                params: {
                                    applicationId: data.applicationId,
                                    accountId: data.accountId,
                                    orderId: data.orderId,
                                },
                            }
                        },
                        filterParams: {
                            filterOptions: ['contains'],
                            newRowsAction: 'keep',
                            clearButton: true,
                            suppressAndOrCondition: true,
                            textFormatter: (value) => {
                                return value.trim()
                            },
                        },
                    },
                    'Customer': {
                        field: ['billingInfo.address.firstName', 'billingInfo.address.lastName'],
                        link: (value, data) => {
                            return {
                                name: 'CustomerView',
                                params: {
                                    applicationId: data.applicationId,
                                    customerId: data.accountId,
                                },
                            }
                        },
                        filter: false,
                        sortable: false,
                    },
                    'Shop': { field: 'applicationName', filter: false, sortable: false },
                    'Date Created': { field: 'dateCreated', filter: false, sortable: true },
                    'Status': { field: 'status', statusChip: true, filter: false, sortable: false },
                    'Total': {
                        field: 'totals.total',
                        cellClass: 'u-bold',
                        filter: false,
                        sortable: false,
                        cellRendererFramework: Currency,
                        cellRendererParams: (params) => {
                            return {
                                value: params.value,
                                type: params.currency,
                            }
                        },
                    },
                    'Date From': {
                        field: 'dateFrom',
                        hide: true,
                    },
                    'Date To': {
                        field: 'dateTo',
                        hide: true,
                    },
                },
                actions: {
                    items: [
                        {
                            icon: 'far fa-envelope',
                            'tooltip': 'send paypermail link',
                            onClick: ({ data }) => {
                                this.sendPayPerMail(data)
                            },
                            isDisabled: () => {
                                return this.isLoading
                            },
                        },
                        {
                            icon: 'fas fa-cloud-download-alt',
                            tooltip: 'download slip',
                            onClick: ({ data }) => {
                                this.downloadSlip(data)
                            },
                            isDisabled: () => {
                                return this.isDownloading
                            },
                        },
                    ],
                },
                paginationType: 'custom',
                paginationPageSize: 50,
                cacheBlockSize: 50,
                defaultColDef: { menuTabs: [] },
            },
        }
    },
    computed: {
        ...mapState('order', ['order']),
    },
    methods: {
        ...mapActions('order', ['fetchOrder']),
        updatePagination (data) {
            this.$set(this, 'pagination', data)
            this.pagination.defaultAmount = this.amountOfDisplayedItems
        },
        downloadSlip (data) {
            const url = CONFIG.API.ROUTES.INVOICES.DOWNLOAD.replace('{objectName}', data.slipUri)
            this.isDownloading = true
            this.addJob(url)
            this.$api.get(url).then(({ data }) => {
                window.open(data.url, '_blank')
            }).catch(err => {
                this.$emit('error', err.response)
            }).finally(() => {
                this.finishJob(url)
                this.isDownloading = false
            })
        },
        async sendPayPerMail (payload) {
            this.isLoading = true
            const url = CONFIG.API.ROUTES.ORDERS.SEND_PAY_PER_MAIL.replace('{customerId}', payload.accountId).replace('{orderId}', payload.orderId)
            this.addJob(url)

            await this.$api.post(url, {}).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: 'Pay by mail link was sent to customer',
                })

                const firstEvent = data.data[0]
                const paymentLink = firstEvent.response.parameters.paymentLink || data.data.response.data.parameters.paymentLink || ''
                this.$segmentClient.sendAction(
                    this,
                    'Send Pay-per-mail',
                    {
                        userId: payload.accountId,
                        orderId: payload.orderId, // Payment method used for this specific order
                        id_order: payload.orderId, // Unique identifier. Combination of order ID and order item ID
                        cartId: '', // Unique identifier of the cart the user actually validated
                        payment: 'adyen',
                        lastname: payload.billingInfo.address.lastName, // User lastname. Would become necessary if we implement guest logic.
                        firstname: payload.billingInfo.address.firstName, // User firstname. Would become necessary if we implement guest logic.
                        order_name: payload.orderId, // Unique identifier of the order (similar to orderId)
                        total_paid: payload.totals.total, // Total amount to be paid by the user
                        paymentlink: paymentLink,
                        orderStateId: '',
                        affiliation: payload.source,
                    }
                )
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                this.isLoading = false
                this.finishJob(url)
            })
        },
        changeAmountOfItems (value) {
            this.$refs.table.setPageSize(value)
            this.amountOfDisplayedItems = value
            this.$refs.table.refreshGrid()
        },
        applyFilter (data) {
            this.$refs.table.applyFilter(data)
        },
    },
}
</script>
